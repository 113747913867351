<app-player *ngFor="let player of game.players, let i = index;" [ngStyle]="{'top.px': 200 + (i * 100)}"
    [playerActive]="i === game.currentPlayer" class="player-position" [name]='player'></app-player>

<div class="game-field">
    <div class="card-stack">
        <img *ngFor="let c of [0, 1, 2, 3], let i = index;" [ngStyle]="{'right.px': i * 5}"
            src="../../assets/img/cards/card_cover.png" alt="card">
        <img (click)="takeCard()" style="right: 25px" class="top-card" src="../../assets/img/cards/card_cover.png"
            alt="card">
        <img *ngIf="takeCardAnimation" class="take-card" style="right: 25px"
            src="../../assets/img/cards/{{currentCard}}.png" alt="card">

        <img *ngFor="let card of game.playedCard" class="played-card" src="../../assets/img/cards/{{card}}.png"
            alt="card">
    </div>
</div>

<app-game-info [card]="currentCard" class="game-info">adsfds</app-game-info>

<button class="add-player-button" mat-fab color="primary" (click)="openDialog()">
    <mat-icon>add</mat-icon>
</button>