<h2 mat-dialog-title>Add new player</h2>
<mat-dialog-content>
  <p>Please enter the name of player</p>
  <mat-form-field>
    <mat-label>name</mat-label>
    <input matInput [(ngModel)]="name">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" >Close</button>
  <button mat-button [disabled]="name.length == 0" [mat-dialog-close]="name" cdkFocusInitial>Ok</button>
</mat-dialog-actions>