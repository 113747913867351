import { Helper } from '../app/helper.js';

export class Game {
    public players: string[] = ['Test Person'];
    public stack: string[] = [];
    public playedCard: string[] = [];
    public currentPlayer: number = 0;

    constructor() {
        for (let i = 1; i <= 13; i++) {
            this.stack.push("ace_" + i);
            this.stack.push("clubs_" + i);
            this.stack.push("hearts_" + i);
            this.stack.push("diamonds_" + i);
        }
        Helper.shuffle(this.stack);
    }
}
